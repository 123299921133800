import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/homepage/homepage/src/layouts/pages.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Datenschutzerklärung`}</h1>
    <h2>{`Grundlegendes`}</h2>
    <p>{`Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den `}<a parentName="p" {...{
        "href": "/impressum"
      }}>{`Websitebetreiber`}</a>{` informieren.`}</p>
    <p>{`Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.`}</p>
    <p>{`Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”)
finden Sie in Art. 4 DSGVO.`}</p>
    <h2>{`Reichweitenmessung & Cookies`}</h2>
    <p>{`Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die entweder von unserem Server oder dem Server Dritter an den Browser des Nutzers übertragen werden. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Website. Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem Endgerät gespeichert werden, können Sie dem Einsatz dieser Dateien hier widersprechen:  `}</p>
    <ul>
      <li parentName="ul">{`Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative: `}<a parentName="li" {...{
          "href": "http://optout.networkadvertising.org/?c=1#!/"
        }}>{`http://optout.networkadvertising.org/?c=1#!/`}</a>{`  `}</li>
      <li parentName="ul">{`Cookie-Deaktivierungsseite der US-amerikanischen Website: `}<a parentName="li" {...{
          "href": "http://optout.aboutads.info/?c=2#!/"
        }}>{`http://optout.aboutads.info/?c=2#!/`}</a>{`  `}</li>
      <li parentName="ul">{`Cookie-Deaktivierungsseite der europäischen Website: `}<a parentName="li" {...{
          "href": "http://optout.networkadvertising.org/?c=1#!/"
        }}>{`http://optout.networkadvertising.org/?c=1#!/`}</a></li>
    </ul>
    <p>{`Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Sie entsprechende Einstellungen vornehmen.`}</p>
    <h2>{`Erfassung und Verarbeitung personenbezogener Daten`}</h2>
    <p>{`Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen. Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.`}</p>
    <p>{`Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind Rückschlüsse auf Ihre Person nicht möglich.`}</p>
    <h2>{`Umgang mit Kontaktdaten`}</h2>
    <p>{`Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.`}</p>
    <h2>{`Umgang mit Kommentaren und Beiträgen`}</h2>
    <p>{`Hinterlassen Sie auf dieser Website einen Beitrag oder Kommentar, wird Ihre IP-Adresse gespeichert. Dies erfolgt aufgrund unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO und dient der Sicherheit von uns als Websitebetreiber: Denn sollte Ihr Kommentar gegen geltendes Recht verstoßen, können wir dafür belangt werden, weshalb wir ein Interesse an der Identität des Kommentar- bzw. Beitragsautors haben.`}</p>
    <h2>{`Google Analytics`}</h2>
    <p>{`Diese Website nutzt aufgrund unserer berechtigten Interessen zur Optimierung und Analyse unseres Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird. Der Dienst (Google Analytics) verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.`}</p>
    <p>{`Google LLC hält das europäische Datenschutzrecht ein und ist unter dem Privacy-ShieldAbkommen zertifiziert:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
      }}>{`https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active`}</a></p>
    <p>{`Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum und in den anderen Vertragsstaaten des Abkommens gekürzt. Nur in Einzelfällen wird die IP-Adresse zunächst ungekürzt in die USA an einen Server von Google übertragen und dort gekürzt. Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte IP-Adresse des Nutzers wird nicht mit anderen von Google gespeicherten Daten kombiniert.`}</p>
    <p>{`Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der Internetnutzung verbundene Dienstleistungen.`}</p>
    <p>{`Die von Google in unserem Auftrag erhobenen Daten werden genutzt, um die Nutzung unseres Online-Angebots durch die einzelnen Nutzer auswerten zu können, z. B. um Reports über die Aktivität auf der Website zu erstellen, um unser Online-Angebot zu verbessern.`}</p>
    <p>{`Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.`}</p>
    <p>{`Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt werden. Folgender Link führt Sie zu dem entsprechenden Plugin: `}<a parentName="p" {...{
        "href": "https://tools.google.com/dlpage/gaoptout?hl=de"
      }}>{`https://tools.google.com/dlpage/gaoptout?hl=de`}</a></p>
    <p>{`Alternativ verhindern Sie mit einem `}<a parentName="p" {...{
        "href": "javascript:gaOptout()"
      }}>{`Klick auf diesen Link`}</a>{`, dass Google Analytics innerhalb dieser Website Daten über Sie erfasst. Mit dem Klick auf obigen Link laden Sie ein „Opt-Out-Cookie“ herunter. Ihr Browser muss die Speicherung von Cookies also hierzu grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig, ist ein erneuter Klick auf den Link bei jedem Besuch dieser Website vonnöten.`}</p>
    <p>{`Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.:  `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://policies.google.com/privacy/partners?hl=de"
        }}>{`https://policies.google.com/privacy/partners?hl=de`}</a>{` (Daten, die von GooglePartnern erhoben werden)  `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adssettings.google.de/authenticated"
        }}>{`https://adssettings.google.de/authenticated`}</a>{` (Einstellungen über Werbung, die Ihnen angezeigt wird)  `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://policies.google.com/technologies/ads?hl=de"
        }}>{`https://policies.google.com/technologies/ads?hl=de`}</a>{` (Verwendung von Cookies in Anzeigen)`}</li>
    </ul>
    <h2>{`Rechte des Nutzers`}</h2>
    <p>{`Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.`}</p>
    <h3>{`Löschung von Daten`}</h3>
    <p>{`Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.`}</p>
    <h3>{`Widerspruchsrecht`}</h3>
    <p>{`Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.`}</p>
    <p>{`Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse: `}<a parentName="p" {...{
        "href": "mailto:mail@joerg-zeipelt.de"
      }}>{`mail@joerg-zeipelt.de`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      